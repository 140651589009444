/* eslint-disable no-nested-ternary */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|

 =========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included 
in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  useColorModeValue,
  Flex,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Heading,
  Icon,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Spinner,
  FormHelperText,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from '@chakra-ui/react';
// Assets
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy,
} from 'react-icons/md';
// Custom components
import MiniStatistics from '~/components/card/MiniStatistics';
import IconBox from '~/components/icons/IconBox';
import PropertiesTable from '~/views/admin/property/components/PropertiesTable';
import UsersTable from '~/views/admin/property/components/UsersTable';
import Card from '~/components/card/Card';
import AuthFetch from '~/core/helpers/fetch';
import { API_CDN_URL, API_BASE_URL } from '~/core/config';
import { useGlobalData } from '~/core/hooks/useGlobalData';
import useTranslations from '~/core/hooks/useTranslations';

export default function Property() {

  const { t } = useTranslations();

  const { state } = useGlobalData();
  const { propertyId } = useParams() as any;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const [ formData, setFormData ] = React.useState<any>( { name: '' } );
  const [ formErrors, setFormErrors ] = React.useState( [] );

  const [ property, setProperty ] = React.useState( {
    id: 0, export: 'not_started', flats: [], users: [], name: '', paid: false, pricePerDevice: 0, closed: false, signed: false, signEveryFlat: false,
  } );
  const [ users, setUsers ] = React.useState( [] );
  const [ heading, setHeading ] = React.useState( '' );
  const [ certifiedPerson, setCertifiedPerson ] = React.useState<any>( undefined );
  const [ devicesCount, setDevicesCount ] = React.useState( 0 );

  const isPropertyClosed = property.signEveryFlat ? property.closed : property.signed;
  const hasLicense = (
    state.user
    && (
      parseFloat( state.user.price ) === 0
      || ( state.user.validUntil && new Date( state.user.validUntil ) > new Date() )
    )
  );

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const getDevicesCount = async () => {

    const devicesJson = await AuthFetch.request( `${API_BASE_URL}/property/property-devices-count?propertyId=${propertyId}`, {
      method: 'GET',
      credentials: 'include',
    } );

    setDevicesCount( devicesJson ?? 0 );

  };

  const getCertifiedPerson = async () => {

    const addresses: any = await AuthFetch.request( `${API_BASE_URL}/admin/addresses`, {
      method: 'GET',
      credentials: 'include',
    } );

    setCertifiedPerson( addresses.certified_person );

    return addresses.certified_person;

  };

  const getUsers = async () => {

    const usersJson = await AuthFetch.request( `${API_BASE_URL}/admin/users-list`, {
      method: 'GET',
      credentials: 'include',
    } );

    return usersJson;

  };

  const getProperty = async () => {

    const propertyJson: any = await AuthFetch.request( `${API_BASE_URL}/property?propertyId=${propertyId}`, {
      method: 'GET',
      credentials: 'include',
    } );
    const usersJson: any = await getUsers();
    const notAssignedUsers = usersJson.filter(
      ( user:any ) => !propertyJson.users.some(
        ( propertyUser:any ) => propertyUser.id === user.id,
      ),
    );

    setProperty( propertyJson );
    setUsers( notAssignedUsers );
    setHeading( propertyJson.name );

    return propertyJson;

  };

  const refetchPropertyUntilExport = async () => {

    const propertyJson = await getProperty();

    if ( propertyJson.export !== 'done' ) {

      setTimeout( () => refetchPropertyUntilExport(), 2000 );

    }

  };

  const assignUser = async ( userId: number ) => {

    await AuthFetch.request( `${API_BASE_URL}/admin/add-user-to-property`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify( { userId, propertyId } ),
    } );

    getProperty();

  };

  const removeUser = async ( userId: number ) => {

    await AuthFetch.request( `${API_BASE_URL}/admin/remove-user-from-property`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify( { userId, propertyId } ),
    } );

    getProperty();

  };

  const handleFormDataChange = ( field: string, value: string ) => {

    let errorFields = [];

    if ( value.trim() === '' && !formErrors.includes( field ) ) {

      errorFields = [ ...formErrors, field ];

    } else {

      errorFields = formErrors.filter( ( error ) => error !== field );

    }

    setFormErrors( errorFields );

    setFormData( ( prevState: any ) => ( {
      ...prevState,
      [field]: value,
    } ) );

  };

  const addFlat = async ( event: any ) => {

    event.preventDefault();

    const errorFields: string[] = [];
    const body: { [key: string]: string } = formData;
    Object.entries( body ).forEach( ( [ field, value ] ) => {

      if ( value.trim() === '' ) {

        errorFields.push( field );

      }

    } );

    setFormErrors( errorFields );

    if ( errorFields.length ) {

      return;

    }

    await AuthFetch.request( `${API_BASE_URL}/property/add-flat`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify( { ...body, propertyId } ),
    } );

    setFormData( { name: '', ...( state?.user?.industry ? { om: '', mp: '' } : {} ) } );
    getProperty();

    onModalClose();

  };

  const onExport = async ( event: any ) => {

    event.preventDefault();

    if ( !property.paid || !hasLicense || !certifiedPerson ) {

      onOpen();

      return;

    }

    // TODO: DEBUG WHY RETURNING ERROR
    setProperty( { ...property, export: 'pending' } );
    try {

      await AuthFetch.request( `${API_CDN_URL}/upload/export/${property.id}`, {
        method: 'POST',
        credentials: 'include',
      } );

    } catch ( e ) {

      console.error( e );

    } finally {

      refetchPropertyUntilExport();

    }

  };

  React.useEffect( () => {

    getProperty();
    getCertifiedPerson();

  }, [] );

  React.useEffect( () => {

    if ( property.id ) {

      getDevicesCount();

    }

  }, [ property ] );

  const h1 = document.querySelector( 'h1' ) as HTMLElement;
  const breadcrumbHeading = document.querySelector( '#breadcrumb-heading' ) as HTMLElement;

  React.useEffect( () => {

    if ( h1 && breadcrumbHeading ) {

      h1.innerText = heading;
      breadcrumbHeading.innerText = heading;

    }

  }, [ heading ] );

  // Chakra Color Mode
  const brandColor = useColorModeValue( 'brand.500', 'white' );
  const boxBg = useColorModeValue( 'secondaryGray.300', 'whiteAlpha.100' );
  const textColor = useColorModeValue( 'secondaryGray.900', 'white' );
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue( 'brand.500', 'brand.400' );
  const errorColor = 'red.500';
  const licenseProblem = !property.paid || !hasLicense;

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid columns={{ base: 1, md: 2, '2xl': 4 }} gap="20px" mb="20px">
        <MiniStatistics
          startContent={(
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdAttachMoney} color={brandColor} />}
            />
    )}
          name={t( 'property.totalPrice' )}
          value={`${( property?.pricePerDevice ?? 0 ) * devicesCount} €`}
        />
        <MiniStatistics
          startContent={(
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />}
            />
    )}
          name={t( 'property.pricePerDevice' )}
          value={`${property?.pricePerDevice ?? 0} €`}
        />
        <MiniStatistics
          startContent={(
            <IconBox
              w="56px"
              h="56px"
              bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
              icon={<Icon w="28px" h="28px" as={MdAddTask} color="white" />}
            />
    )}
          name={t( 'property.installedDevices' )}
          value={devicesCount}
        />
        <MiniStatistics
          startContent={(
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdFileCopy} color={brandColor} />}
            />
    )}
          name={t( 'property.flats' )}
          value={property.flats.length}
        />
      </SimpleGrid>
      { isPropertyClosed && (
      <Card mb="20px" mx="auto">
        <Box textAlign="center">
          <Heading color={textColor} fontSize="36px" mb="20px">
            { t( 'property.export.heading' ) }
          </Heading>
          { property?.export === 'not_started' && (
          <Button
            onClick={onExport}
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            px="50px"
            h="50"
          >
            { t( 'property.export.action' ) }
          </Button>
          )}
          { property?.export === 'pending' && (
          <Flex justifyContent="center" alignItems="center" gap={5}>
            <Spinner color={brandColor} size="lg" />
            <Text color={textColorSecondary} fontWeight="400" fontSize="md">
              { t( 'property.export.pending' ) }
            </Text>
          </Flex>
          )}
          { property?.export === 'done' && (
          <Flex justifyContent="center" alignItems="center" flexWrap="wrap" gap={5}>
            <a href={`${API_CDN_URL}/uploads/export/${property.id}/property.xlsx`} target="_blank" download="property.xlsx" rel="noreferrer">
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                h="50"
              >
                { t( 'property.export.download.excel' ) }
              </Button>
            </a>
            <a href={`${API_CDN_URL}/uploads/export/${property.id}/property.csv`} target="_blank" download="property.csv" rel="noreferrer">
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                h="50"
              >
                { t( 'property.export.download.csv' ) }
              </Button>
            </a>
            <a href={`${API_CDN_URL}/uploads/export/${property.id}/${property.id}.zip`} target="_blank" download="property.zip" rel="noreferrer">
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                h="50"
              >
                { t( 'property.export.download.zip' ) }
              </Button>
            </a>
          </Flex>
          )}
        </Box>
      </Card>
      )}
      {/* { false && (
      <Card w="100%" mb="20px">
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            {`Export ${property.name ?? ''}`}
          </Heading>
          <Text
            mb="20px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            This property is closed and ready for export
          </Text>
          <Button
            onClick={onExport}
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
          >
            Export
          </Button>
        </Box>
      </Card>
      )} */}
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <UsersTable
          assign={assignUser}
          type="ADD"
          title={t( 'property.usersTable.available.heading' )}
          description={t( 'property.usersTable.available.description' )}
          tableData={users}
        />
        <UsersTable
          assign={removeUser}
          type="DELETE"
          title={t( 'property.usersTable.assigned.heading' )}
          description={t( 'property.usersTable.assigned.description' )}
          tableData={property.users}
        />
      </SimpleGrid>

      <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
        <Box px="25px">
          <Flex justifyContent="space-between" mb="18px" flexDirection={{ base: 'column', md: 'row' }}>
            <Box>
              <Heading color={textColor} fontSize="36px" mb="10px">
                { t( 'property.table.heading' ) }
              </Heading>
              <Text
                mb="18px"
                ms="4px"
                color={textColorSecondary}
                fontWeight="400"
                fontSize="md"
              >
                { t( 'property.table.description' ) }
              </Text>
            </Box>

            <Button
              onClick={onModalOpen}
              variant="brand"
              fontWeight="500"
              h="48px"
              w="fit-content"
              ms={{ base: '0', md: '32px' }}
            >
              { t( 'property.new.new' ) }
            </Button>
          </Flex>
        </Box>

        <PropertiesTable property={property} tableData={property.flats} onOpen={onModalOpen} />
      </Card>

      <Modal isCentered isOpen={isModalOpen} onClose={onModalClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={textColor} fontSize="36px" mb="10px">
            <Heading color={textColor} fontSize="36px" mb="10px">
              { t( 'property.new.heading', { name: property.name ?? '' } ) }
            </Heading>
            <Text
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              { t( 'property.new.description' ) }
            </Text>
          </ModalHeader>
          <ModalBody>
            <Flex
              direction="column"
              w={{ base: '100%' }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: 'auto', lg: 'unset' }}
              me="auto"
              mb={{ base: '20px', md: 'auto' }}
            >
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  { t( 'property.new.name.label' ) }
                  <Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  value={formData.name}
                  isRequired
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: '0px', md: '0px' }}
                  type="text"
                  placeholder={t( 'property.new.name.placeholder' )}
                  fontWeight="500"
                  size="lg"
                  mb="8px"
                  borderColor={formErrors.includes( 'name' ) ? errorColor : undefined}
                  onChange={( e ) => handleFormDataChange( 'name', e.target.value )}
                />
                {formErrors.includes( 'name' ) && (
                  <FormHelperText ms="4px" color={errorColor}>
                    { t( 'error', { label: t( 'property.new.name.label' ) } ) }
                  </FormHelperText>
                )}
                { Boolean( state?.user?.industry ) && (
                  <>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      { t( 'property.new.OM.label' ) }
                      <Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Input
                      value={formData.om}
                      isRequired
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      type="text"
                      placeholder={t( 'property.new.OM.placeholder' )}
                      fontWeight="500"
                      size="lg"
                      mb="8px"
                      borderColor={formErrors.includes( 'om' ) ? errorColor : undefined}
                      onChange={( e ) => handleFormDataChange( 'om', e.target.value )}
                    />
                    {formErrors.includes( 'om' ) && (
                      <FormHelperText ms="4px" color={errorColor}>
                        { t( 'error', { label: t( 'property.new.OM.label' ) } ) }
                      </FormHelperText>
                    )}
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      { t( 'property.new.MP.label' ) }
                      <Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Input
                      value={formData.mp}
                      isRequired
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: '0px', md: '0px' }}
                      type="text"
                      placeholder={t( 'property.new.MP.placeholder' )}
                      fontWeight="500"
                      size="lg"
                      borderColor={formErrors.includes( 'mp' ) ? errorColor : undefined}
                      onChange={( e ) => handleFormDataChange( 'mp', e.target.value )}
                    />
                    {formErrors.includes( 'mp' ) && (
                    <FormHelperText ms="4px" color={errorColor}>
                      { t( 'error', { label: t( 'property.new.MP.label' ) } ) }
                    </FormHelperText>
                    )}
                  </>
                )}
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onModalClose}
              fontSize="sm"
              fontWeight="500"
              h="48px"
              mr="6px"
            >
              { t( 'property.new.close' ) }
            </Button>
            <Button
              onClick={addFlat}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              h="48px"
            >
              { t( 'property.new.submit' ) }
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {licenseProblem ? ( hasLicense ? t( 'property.license.payForExport' ) : t( 'property.license.payForLicence' ) ) : t( 'property.noCertifiedPerson.title' )}
            </AlertDialogHeader>

            <AlertDialogBody>
              { licenseProblem ? (
                hasLicense ? (
                  <>
                    { t( 'property.license.pricePerDevice', { pricePerDevice: property?.pricePerDevice ?? 0 } ) }
                    <br />
                    { t( 'property.license.devicesCount', { devicesCount } ) }
                  </>
                ) : (
                  <>
                    { t( 'property.license.buyLicence' ) }
                  </>
                )
              ) : t( 'property.noCertifiedPerson.description' ) }
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} borderRadius={6} size="sm">
                { t( 'property.license.cancel' ) }
              </Button>
              { licenseProblem && (
                hasLicense ? (
                  <form action={`${API_BASE_URL}/payment/export/${state.user?.id}/${property?.id}`} method="POST">
                    <Button type="submit" borderRadius={6} colorScheme="teal" variant="solid" size="sm" onClick={onClose} ml={3}>
                      {`${t( 'property.license.pay' )} ${( property?.pricePerDevice ?? 0 ) * devicesCount} €`}
                    </Button>
                  </form>
                ) : (
                  <form action={`${API_BASE_URL}/payment/license/${state.user?.id}`} method="POST">
                    <Button type="submit" borderRadius={6} colorScheme="teal" variant="solid" size="sm" onClick={onClose} ml={3}>
                      {`${t( 'property.license.pay' )} ${state?.user?.price} €`}
                    </Button>
                  </form>
                )
              ) }
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <CheckTable tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <ComplexTable tableData={tableDataComplex} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <Tasks />
          <MiniCalendar h="100%" minW="100%" selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );

}
